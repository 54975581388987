import React from "react"
import { useState } from "react";
import "./Login.css";


async function loginUser(credentials) {
  return fetch("/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export default function Login( {setToken} ) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e=> {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });
    if (token["access_token"] !== undefined) {
      setToken(token["access_token"]);
      localStorage.setItem('token', token["access_token"])
    }
  }

	return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>User</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Enter username"
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
