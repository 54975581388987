import 'react-dropzone-uploader/dist/styles.css'
import "./App.css";
import Login from "./components/Login/Login"
import DockInfo from "./components/DockInfo";
import DownstreamDevices from "./components/DownstreamDevices";
import TextMessage from "./components/TextMessage";
import React, { useEffect, useState } from "react";
import { DockSelection } from "./components/DockInfo";
import ErrorToast from './components/ErrorToast';

let refreshTimer = undefined
let aborter = new AbortController()

function getIndexOfLastSelectedDock(docks) {
  const lastSelectedDock = localStorage.getItem("lastSelectedDock")
  for (const dock of docks) {
    if (dock.id == lastSelectedDock) {
      return dock.id
    }
  }
  return docks[0].id
}

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [docksData, setDocksData] = useState();
  const [dockData, setDockData] = useState();
  const [selectedDock, setSelectedDock] = useState(null);
  const [errorReason, setErrorReason] = useState("No reason");
  const [errorMessage, setErrorMessage] = useState("This should not appear!");
  const [showError, setShowError] = useState(false);

  function showErrorMessage(reason, message) {
    setErrorReason(reason)
    setErrorMessage(message)
    setShowError(true)
  }

  const handleLogout = async () => {
    localStorage.removeItem("token")
    localStorage.removeItem("lastSelectedDock")
    setToken(null)
    setSelectedDock(null)
  };


  // Fetch the data here
  useEffect(() => {
    if (!!token) {
      clearTimeout(refreshTimer)
      // Timeouts only get set after a useEffect finishes, and are cleared when a useEffect starts.
      // Therefore a timeout useEffect cannot abort an existing fetch.
      // So if a fetch is being aborted, the current useEffect was due to user action.
      // User action that has happened after the prior fetch will mean any data that the prior fetch will return will be outdated. So abort it!
      aborter.abort()
      aborter = new AbortController()
      fetch("/docks", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        signal: aborter.signal
        }
      )
      .then((response) => response.json())
      .then((json) => {
        if (json === undefined) {
          showErrorMessage("Fetch empty", "Fetch returned 'undefined'!")
        } else if (!Array.isArray(json)) {
          console.log(json)
          showErrorMessage("Fetch malformed", "Fetch did not return an array! See console for actual output.")
        } else {
          if (selectedDock === null && json[0] !== undefined) {
            const dockId = getIndexOfLastSelectedDock(json)
            setSelectedDock(dockId)
          }
          setDocksData(json)
          setDockData(json.find((dock) => dock.id === selectedDock))
        }
      })
      .catch((err) => {
        console.log(err.message)
        if (err.name !== 'AbortError') {
          showErrorMessage("Fetch failed", "Could not fetch data about docks! See console for details.")
        }
      })
    }
  }, [token, selectedDock])

  if (!token) {
    return (
      <main className="App">
        <ErrorToast reason={errorReason} message={errorMessage} show={showError} setShow={setShowError}/>
        <Login setToken={setToken}/>
      </main>
    );
  }

  return (
    <main className="App">
      <ErrorToast reason={errorReason} message={errorMessage} show={showError} setShow={setShowError}/>
      <h3>
        <img src="synaptics-logo-full-color.png" height="100" alt="" />
      </h3>
      <div className="line">
        <h2></h2>
        <h2>IoT Dock:</h2>
        <DockSelection docksData={docksData} dockData={dockData} setSelectedDock={setSelectedDock}/>
        <h2></h2>
        {/* <DockInfo dockData={dockData}/> */}
        <DownstreamDevices token={token} dockData={dockData} selectedDock={selectedDock}/>
      </div>
      <div className="line">
        <TextMessage token={token} selectedDock={selectedDock}/>
      </div>
      <button onClick={handleLogout} className="logout">
        Logout
      </button>
    </main>
  );
}

export default App;
