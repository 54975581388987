import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Form from 'react-bootstrap/Form';

function sendMessage(message, token, dockId) {
  console.log(message);
  console.log(dockId);
  fetch("/text", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    body: JSON.stringify({
      "message": message,
      "dockId": dockId,
    })
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    })
    .catch((error) => console.log(error));
}

export default function TextMessage({token, selectedDock}) {
  const [selectionValue, setSelectionValue] = useState('0');
  const options = [
    {
      html: <>Info: <b>Company Anniversary Celebration</b><br/>Today @ 10:00 in the Atrium</>,
      value: "1",
      message: "<span size=\"large\">Info: <b>Company Anniversary Celebration</b>\nToday @ 10:00 in the Atrium</span>",
    },
    {
      html: <><b>Important: Planned Power Outage</b><br/>Logout before 20:00 tonight</>,
      value: "2",
      message: "<span size=\"large\"><b>Important: Planned Power Outage</b>\nLogout before 20:00 tonight</span>"
    },
    {
      html: <>Clear</>,
      value: "3",
      message: "",
    }
  ]

  const [message, setMessage] = useState("");

  const handleText = (e) => {
    const inputMessage = e.target.value;
    setMessage(inputMessage);
  };

  const handleInputText = () => {
    if (!message) {
      return;
    }
    const nlines = message.split("\n").length;
    if (nlines > 3) {
      alert("Messages can contain 3 lines maximum!");
      return;
    }
    sendMessage(message, token, selectedDock)
    setMessage("");
  };

  return (
    <div>
      <h2></h2>
      <h2>Splashscreen Text</h2>
      <div className="d-grid gap-1 p-1">
        <ButtonGroup vertical title="Choose message.">
          {options.map((option, idx) => (
            <ToggleButton
              className="pt-0 pb-5"
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant="outline-primary"
              value={option.value}
              checked={selectionValue === option.value}
              onClick={() => sendMessage(option.message, token, selectedDock)}
              onChange={(e) => setSelectionValue(e.currentTarget.value)}
            >
              {option.html}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <div className="d-flex align-items-center">
          <Form.Control type="text" as="textarea" value={message} onChange={handleText} rows={3} placeholder="Custom message text"/>
          <Button variant="primary" class="align-self-stretch" onClick={() => handleInputText()}>Send</Button>{' '}
        </div>
      </div>
    </div>
  );
}
