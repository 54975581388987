import React, {useEffect, useState} from "react";

import Button from "react-bootstrap/Button";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Table from "react-bootstrap/Table";

function DownstreamDevices({token, dockData, selectedDock}) {
  const [targetVersions, setTargetVersions] = useState([]);
  const [dock, setDock] = useState();

  function handleTargetVersionClick(index, newTarget) {
    const nextTargetVersions = targetVersions.map((currentTarget, i) => {
      if (i === index) {
        return newTarget;
      } else {
        return currentTarget;
      }
    })
    setTargetVersions(nextTargetVersions)
  }

  useEffect(() => {
    if (dockData !== undefined) {
      if (dock !== dockData.id) {
        let currentVersions = []
        dockData["downstreamDevices"].forEach((downstreamDevice) => {
          currentVersions.push(downstreamDevice.currentVersion)
        })
        setTargetVersions(currentVersions);
        setDock(dockData.id);
      }
    }
  }, [dock, dockData]);

  return (
    <>
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>Downstream Device</th>
          <th>Current Version</th>
          <th>Target Version</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
          {dockData === undefined? "Loading...":
            dockData["downstreamDevices"].map((downstreamDevice, deviceIndex) => {
            return (
              <tr>
                <td>
                  {downstreamDevice.deviceId}
                </td>
                <td>
                  {downstreamDevice.currentVersion}
                </td>
                <td>
                  {downstreamDevice.availableVersions[0] === undefined ? targetVersions[deviceIndex]:
                    <DropdownButton title={targetVersions[deviceIndex]}>
                      {downstreamDevice.availableVersions.map((version, versionIndex) => {
                        return (
                          <Dropdown.Item eventKey={versionIndex}>
                            <div onClick={() => {
                              handleTargetVersionClick(deviceIndex, version);
                              sendFirmwareTargetRequest(version, downstreamDevice.deviceId, token, selectedDock);
                            }}>
                              {version}
                            </div>
                          </Dropdown.Item>)
                      })}
                    </DropdownButton>
                  }
                </td>
                <td>
                  {downstreamDevice.availableVersions[0] === undefined?
                    <Button variant="secondary" disabled>
                      Update
                    </Button>
                    :
                    <Button onClick={() => {
                      sendFirmwareUpdateRequest(targetVersions[deviceIndex], downstreamDevice.deviceId, token, selectedDock)
                    }}>
                      Update
                    </Button>
                  }
                </td>
              </tr>)
          })}
        </tbody>
      </Table>
    </>
  );
}


function sendFirmwareUpdateRequest(version, deviceId, token, dockId){
  console.log("sending Firmware Update Request for : \n\tDownstream Device = " + deviceId + "\n\tNew Firmware Version : " + version);

  fetch("/firmware", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    body: JSON.stringify({
      "type": "firmware",
      "device_id": deviceId,
      "version": version,
      "dock_id": dockId,
    })
  })
    .catch((error) => console.log(error));
}

function sendFirmwareTargetRequest(version, deviceId, token, dockId){
  console.log("sending Firmware Target Request for : \n\tDownstream Device = " + deviceId + "\n\tNew Firmware Version : " + version);

  fetch("/firmware", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    body: JSON.stringify({
      "type": "firmwareTarget",
      "device_id": deviceId,
      "version": version,
      "dock_id": dockId,
    })
  })
    .catch((error) => console.log(error));
}

export default DownstreamDevices;
